<template>
  <v-card class="mx-auto" :disabled="tbl_ldg">
    <v-card-title>
      <v-row dense>
        <v-col cols="12" xs="12" md="8">
          <span class="text-h6 ml-1" v-text="$route.meta.title" />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
          <v-tooltip v-if="login.role_id == 1" bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="teal"
                :loading="btn_xls_ldg"
                :disabled="tbl.length == 0"
                @click.prevent="toXls"
              >
                <v-icon> mdi-table-arrow-down </v-icon>
              </v-btn>
            </template>
            <span v-text="'Descargar tabla XLS'" />
          </v-tooltip>
        </v-col>
        <v-col cols="12" xs="12" md="3">
          <v-text-field
            label="Buscar"
            v-model="txt"
            type="text"
            dense
            maxlength="20"
            :disabled="tbl.length > 0"
          />
        </v-col>
        <v-col cols="12" xs="12" md="3">
          <v-select
            label="Por"
            v-model="type"
            :items="types"
            item-value="id"
            :item-text="(v) => v.name"
            dense
            :disabled="tbl.length > 0"
          />
        </v-col>
        <v-col cols="12" xs="12" md="3">
          <v-select
            label="Para servicios"
            v-model="for_rss"
            :items="for_rss_opt"
            item-value="id"
            :item-text="(v) => v.name"
            dense
            :disabled="tbl.length > 0"
          />
        </v-col>
        <v-col v-if="tbl.length == 0" cols="12">
          <v-btn
            block
            small
            color="info"
            @click.prevent="getTblData"
            :loading="tbl_ldg"
            :disabled="txt == ''"
          >
            Buscar
            <v-icon right> mdi-magnify </v-icon>
          </v-btn>
        </v-col>
        <v-col v-else cols="12">
          <v-btn
            block
            small
            @click.prevent="
              tbl = [];
              insured = null;
            "
          >
            Cambiar parametros
            <v-icon right> mdi-refresh </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text v-if="tbl.length > 0">
      <v-row dense>
        <v-col cols="12">
          <v-card>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <h2 class="text-caption" v-text="'ASEGURADO'" />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items />
            </v-toolbar>
            <v-divider />
            <v-card-text>
              <v-row v-if="insured" dense>
                <v-col cols="12" xs="12" md="6">
                  <ViewData label="Nombre" :value="insured.full_name" />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData label="ID SM" :value="insured.enrollment" />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Núm. credencial"
                    :value="insured.credential"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="6">
                  <ViewData
                    label="Contratante"
                    :value="insured.contractor.name"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData label="Oficina" :value="insured.office_address" />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData label="F. Nacimiento" :value="insured.birthday" />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData label="Vigencia" :value="insured.validity" />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Tipo"
                    :value="insured.insured_type.insured_type"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <div
                    class="text-caption font-weight-bold"
                    v-text="'Trat. méd'"
                  />
                  <div>
                    <v-icon v-if="insured.medical_treatment" small>
                      mdi-close
                    </v-icon>
                    <v-icon v-else color="success" small> mdi-check </v-icon>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-card>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <h2 class="text-caption" v-text="'RESULTADO'" />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items />
            </v-toolbar>
            <v-divider />
            <v-card-text>
              <v-row dense>
                <v-col cols="12" sm="12" md="9">
                  <ViewData label="Registros" :value="tbl.length" />
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <v-text-field
                    v-model="tbl_search"
                    append-icon="mdi-magnify"
                    label="Buscar..."
                    single-line
                    hide-details
                    dense
                    :disabled="tbl.length == 0"
                  />
                </v-col>
                <v-col cols="12">
                  <v-data-table
                    :headers="tbl_headers"
                    :search="tbl_search"
                    :items="tbl"
                    :loading="tbl_ldg"
                    :items-per-page="15"
                    dense
                    :item-class="rowBg"
                  >
                    <template v-slot:item.index="{ item, index }">
                      <b v-text="index + 1" />
                    </template>
                    <template v-slot:item.folio="{ item }">
                      <div>
                        <span>
                          {{ item.folio }}
                        </span>
                        <v-tooltip
                          v-if="item.rha_id && login.permissions.rhas.read"
                          left
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              color="info"
                              x-small
                              icon
                              :to="{
                                name: 'admission_request.detalle',
                                params: { id: item.rha_id },
                              }"
                            >
                              <v-icon small> mdi-eye </v-icon>
                            </v-btn>
                          </template>
                          <span v-text="'Detalle'" />
                        </v-tooltip>
                        <v-tooltip
                          v-if="item.rs_id && login.permissions.rss.read"
                          left
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              color="info"
                              x-small
                              icon
                              :to="{
                                name: 'servicios.detalle',
                                params: { id: item.rs_id },
                              }"
                            >
                              <v-icon small> mdi-eye </v-icon>
                            </v-btn>
                          </template>
                          <span v-text="'Detalle'" />
                        </v-tooltip>
                      </div>
                      <div v-if="item.rs_service_with_rha">
                        <small>
                          <span>
                            {{ item.rs_service_with_rha.folio }}
                          </span>
                          <v-tooltip v-if="login.permissions.rhas.read" left>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                v-on="on"
                                color="pink"
                                dark
                                x-small
                                icon
                                :to="{
                                  name: 'admission_request.detalle',
                                  params: { id: item.rs_service_with_rha.id },
                                }"
                              >
                                <v-icon x-small> mdi-eye </v-icon>
                              </v-btn>
                            </template>
                            <span v-text="'Detalle'" />
                          </v-tooltip>
                        </small>
                      </div>
                    </template>
                    <template v-slot:item.insured.full_name="{ item }">
                      <span :class="item.insured.id ? '' : 'orange--text'">
                        {{ item.insured.full_name }}
                      </span>
                    </template>
                    <template v-slot:item.deducible_amount="{ item }">
                      {{
                        item.deducible_amount != null
                          ? numberFormat(item.deducible_amount)
                          : null
                      }}
                    </template>
                    <template v-slot:item.bill="{ item }">
                      <span :class="item.bill_active ? '' : 'red--text'">
                        {{ (item.bill_active ? "" : "*") + item.bill }}
                      </span>
                      <v-tooltip
                        v-if="
                          item.rs_service_id &&
                          item.bill_id &&
                          login.permissions.rs_service_bills.read
                        "
                        left
                      >
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            color="warning"
                            x-small
                            icon
                            :to="{
                              name: 'facturacion_servicios.detalle',
                              params: { id: item.bill_id },
                            }"
                          >
                            <v-icon small> mdi-eye </v-icon>
                          </v-btn>
                        </template>
                        <span v-text="'Detalle'" />
                      </v-tooltip>
                      <v-tooltip
                        v-if="
                          item.rha_id &&
                          item.bill_id &&
                          login.permissions.rha_bills.read
                        "
                        left
                      >
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            color="warning"
                            x-small
                            icon
                            :to="{
                              name: 'facturacion.finder',
                              params: { rha_bill_id: item.bill_id },
                            }"
                          >
                            <v-icon small> mdi-eye </v-icon>
                          </v-btn>
                        </template>
                        <span v-text="'Detalle'" />
                      </v-tooltip>
                    </template>
                    <template v-slot:item.amount="{ item }">
                      {{ item.amount ? numberFormat(item.amount) : null }}
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  msgAlert,
  base64ToArrayBuffer,
  dateTimeToFile,
} from "../../control";
import FaqDlg from "../../components/FaqDlg.vue";
import ViewData from "../../components/ViewData.vue";

export default {
  components: {
    FaqDlg,
    ViewData,
  },
  data() {
    return {
      login: this.$store.getters.getLogin,
      for_rss: 0,
      for_rss_opt: [
        {
          id: 1,
          name: "SOLO CON CARTAS",
        },
        {
          id: 0,
          name: "TODOS",
        },
      ],
      type: 1,
      types: [
        {
          id: 1,
          name: "FOLIO",
        },
        {
          id: 2,
          name: "ID SM",
        },
      ],
      txt: "",
      insured: null,
      tbl: [],
      tbl_ldg: false,
      tbl_search: "",
      tbl_headers: [
        {
          value: "index",
          text: "#",
          filterable: false,
          width: "60",
        },
        {
          value: "folio",
          text: "Folio",
          filterable: true,
          width: "150",
        },
        {
          value: "created_at",
          text: "F. creación",
          filterable: true,
        },
        {
          value: "insured.enrollment",
          text: "ID SM",
          filterable: true,
        },
        {
          value: "insured.credential",
          text: "N. credencial",
          filterable: true,
        },
        {
          value: "insured.full_name",
          text: "Asegurado",
          filterable: true,
        },
        {
          value: "insured.insured_type.insured_type",
          text: "Tipo",
          filterable: true,
        },
        {
          value: "icd_txt",
          text: "ICD",
          filterable: true,
        },
        {
          value: "type",
          text: "Tipo",
          filterable: true,
        },
        {
          value: "provider_type",
          text: "T. Prov. | Espec.",
          filterable: true,
        },
        {
          value: "provider_txt",
          text: "Proveedor | Médico",
          filterable: true,
        },
        {
          value: "accident",
          text: "Padecimiento",
          filterable: true,
        },
        {
          value: "initial",
          text: "Siniestro",
          filterable: true,
        },
        {
          value: "sinister_number",
          text: "Núm. Siniestro",
          filterable: true,
        },
        {
          value: "deducible_amount",
          text: "Deducible",
          filterable: true,
        },
        {
          value: "coinsurance",
          text: "Coaseguro %",
          filterable: true,
        },
        {
          value: "bill",
          text: "Facturado",
          filterable: true,
          width: "130",
        },
        {
          value: "paid_date",
          text: "F. Pago",
          filterable: true,
          width: "120",
        },
        {
          value: "letter_date",
          text: "F. carta",
          filterable: true,
        },
        {
          value: "amount",
          text: "Monto",
          filterable: true,
        },
      ],
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
      btn_xls_ldg: false,
    };
  },
  methods: {
    numberFormat(v) {
      return Number(parseFloat(v).toFixed(2)).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },

    getTblData() {
      this.tbl_ldg = true;
      this.tbl = [];
      this.insured = null;

      Axios.get(
        URL_API +
          "/finder/main" +
          "?for_rss=" +
          this.for_rss +
          "&type=" +
          this.type +
          "&txt=" +
          this.txt,
        headersToken(this.login.token)
      ).then((res) => {
        this.tbl = res.data.data.items;

        if (this.tbl.length > 0) {
          this.insured = res.data.data.insured;
        } else {
          this.$swal.fire(msgAlert("warning", "No se obtuvieron resultados"));
        }

        this.tbl_ldg = false;
      });
    },

    toXls() {
      this.btn_xls_ldg = true;

      Axios.post(
        URL_API + "/finder/main/xlsx",
        {
          items: this.tbl,
        },
        headersToken(this.login.token)
      ).then((rsp) => {
        if (rsp.data.success) {
          const blob = new Blob([base64ToArrayBuffer(rsp.data.data)], {
            type: "application/xlsx",
          });
          let downloadLink = document.createElement("a");
          downloadLink.setAttribute("target", "_blank");
          downloadLink.href = URL.createObjectURL(blob);
          downloadLink.download =
            "SM_restituciones_" + dateTimeToFile() + ".xlsx";
          downloadLink.click();

          this.btn_xls_ldg = false;
        } else {
          this.$swal.fire(msgAlert("error", "Error al generar archixo XLSX"));
        }

        this.btn_xls_ldg = false;
      });
    },

    rowBg: function (item) {
      let bg = "";

      if (!item.active) {
        bg = "red lighten-4";
      }

      return bg;
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((res) => {
      this.faqs = res.data.data;
      this.faqs_ldg = false;
    });
  },
};
</script>